import React, { useState, useEffect } from 'react';
import './NavBar.css';
import './Home.css';
import './Gallery.css';

// Move categoryFolders outside the component to avoid dependency issues
const categoryFolders = {
  'Pencil Art': '/media/pencil/',
  'Paintings': '/media/paintings/',
};

function App() {
  const [currentSection, setCurrentSection] = useState('home');
  const [selectedCategory, setSelectedCategory] = useState('Pencil Art');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [images, setImages] = useState([]);

  const handleNavigation = (section) => {
    setCurrentSection(section);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const loadImages = (index = 1) => {
      const folderPath = categoryFolders[selectedCategory];
      const imagePath = `${folderPath}image${index}.png`;
      const img = new Image();

      img.onload = () => {
        setImages((prevImages) => {
          if (!prevImages.includes(imagePath)) {
            return [...prevImages, imagePath];
          }
          return prevImages;
        });
        loadImages(index + 1); // Load the next image
      };

      img.onerror = () => {
        // Stop loading when an image does not exist
      };

      img.src = process.env.PUBLIC_URL + imagePath;
    };

    setImages([]); // Clear previous images
    loadImages(); // Start loading images
  }, [selectedCategory]); // This effect runs every time selectedCategory changes

  const handleImageError = (event) => {
    event.target.style.display = 'none';
  };

  return (
    <div className="app">
      <header className="app-header">
        <div className="logo">
          emely.studio
        </div>
        <nav className="nav-bar">
          <ul>
            <li><a href="#home" onClick={() => handleNavigation('home')}>Home</a></li>
            <li><a href="#gallery" onClick={() => handleNavigation('gallery')}>Gallery</a></li>
            <li><a href="#about" onClick={() => handleNavigation('about')}>About</a></li>
          </ul>
        </nav>
      </header>
      <div className="header-line"></div>

      {currentSection === 'home' && (
        <>
          <div className="image-container">
            <img src={process.env.PUBLIC_URL + '/media/home.png'} alt="Home" className="home-image" />
          </div>
          <div className="bottom-half">
            <div className="subheader">
              Socials
            </div>
            <div className="vertical-divs">
              <div className="rounded-div">Instagram</div>
              <div className="rounded-div">Twitter</div>
            </div>
          </div>
        </>
      )}

      {currentSection === 'gallery' && (
        <>
          <div className="gallery-header">
            <h1 className="header-title">{selectedCategory}</h1>
            <div className="custom-dropdown">
              <div className="dropdown-header" onClick={toggleDropdown}>Categories</div>
              {dropdownOpen && (
                <ul className="dropdown-list">
                  <li onClick={() => handleCategoryChange('Pencil Art')}>Pencil Art</li>
                  <li onClick={() => handleCategoryChange('Paintings')}>Paintings</li>
                </ul>
              )}
            </div>
          </div>
          <div className="black-box">
            <div className="image-gallery">
              {images.map((image, index) => (
                <img
                  src={process.env.PUBLIC_URL + image}
                  alt={`${selectedCategory} ${index + 1}`}
                  key={index}
                  className="gallery-image"
                  onError={handleImageError}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {currentSection === 'about' && (
        <div className="about-section">
        </div>
      )}
    </div>
  );
}

export default App;